import { Box } from "@mui/material";
import React from "react";
import ContactForm from "../components/ContactForm";
import Header from "../components/Header";
import Footer from "../components/Footer";

const ContactMobile = () => {
    return (
        <>
          <Header />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              minHeight: "calc(100vh - 300px)",
              width: "100%",
              bgcolor: "background.paper",
              mt: { xs: 1, md: 2 },
              pb: 0,
              px: { xs: 2, md: 0 }, // Add padding for smaller screens
              overFlowX: "hidden",
            }}
          >
            <Box
              sx={{
                width: "100%",
                maxWidth: { xs: "100%", sm: "90%", md: "80%", lg: "1200px" }, // Responsive width
                padding: { xs: 1, md: 2 }, // Adjust padding for smaller screens
                marginTop: 0, // Ensure no additional margin is added here
                marginBottom: 0,
              }}
            >
              <ContactForm />
            </Box>
          </Box>
          <Footer />
        </>
      );
};

export default ContactMobile;
