import React from "react";
import { useMediaQuery } from "react-responsive";
import ContactPage from "../pages/ContactUs.jsx";
import ContactMobile from "../pages/ContactUsMobile.jsx";

const ResponsiveContactUsPage = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 }); // Mobile breakpoint
  return isMobile ? <ContactMobile /> : <ContactPage />;
};

export default ResponsiveContactUsPage;
