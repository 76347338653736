import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import couple_cyclist_photo from "../img/Couple_Cyclists.jpg";
import real_connections_photo from "../img/Real_Connections.jpg";
import consistency_reward_photo from "../img/Consistency_Reward.jpg";
import Header from "../components/Header.jsx";
import Footer from "../components/Footer.jsx";
import FluidEngine from "../components/FluidEngine.jsx";
import Popup from "../components/Popup.jsx";
import MailForm from "../components/mailform.jsx";
import Sponsors from "../components/Sponsors.jsx";
import wireframe from "../img/Wireframe.png";

const HomePageMobile = () => {
  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "100vh", // Ensures it covers the full viewport height
        overflowY: "auto", // Enables vertical scrolling
        bgcolor: "white",
        display: "flex",
        flexDirection: "column",
        overFlowX: "hidden",
      }}
    >
      <Header />
      <Popup />

      <Box
        sx={{
          width: "90%",
          margin: "10px auto",
          borderRadius: "16px",
          backgroundColor: "white",
        }}
      >
        <br></br>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12}>
            <Typography
              variant="h4" // Smaller font for mobile
              sx={{
                fontWeight: "bold",
                fontFamily: "'Manrope', sans-serif",
                color: "#000",
                textAlign: { xs: "center", md: "left" },
                fontSize: { xs: "2.5rem", sm: "3rem", md: "5rem" }, // Adjusted for smaller screens
                lineHeight: 1.25,
                letterSpacing: "0.05em",
              }}
            >
              Connect.
              <br />
              Train.
              <br />
              Achieve 
              <br />
              Together.
            </Typography>
            <br></br>
          </Grid>
          <Grid item xs={12}>
            <Box
              component="img"
              src={wireframe}
              alt="Wireframe Design"
              sx={{
                width: "100%",
                borderRadius: "16px",
                maxHeight: { xs: "300px", md: "auto" }, // Limit image height on mobile
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <Container>
        <br></br><br></br><br></br>
        {/** First Section */}
        <Grid container spacing={4} sx={{ mt: 2 }}>
          <Grid item xs={12}>
            <Typography
              variant="h5"
              sx={{
                fontWeight: "medium",
                fontFamily: "'Manrope', sans-serif",
                textAlign: { xs: "center", md: "right" },
              }}
            >
              Perfect Workout Partners.
            </Typography>
            <Typography
              variant="body1"
              sx={{
                mt: 1,
                fontFamily: "'Manrope', sans-serif",
                textAlign: { xs: "center", md: "right" },
              }}
            >
              Find{" "}
              <span style={{ color: "#fc7032" }}>
                like-minded fitness buddies
              </span>{" "}
              who keep you motivated and on track.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box
              component="img"
              src={couple_cyclist_photo}
              alt="Couple Cyclist"
              sx={{ width: "100%", borderRadius: "16px" }}
            />
          </Grid>
        </Grid>

        {/** Second Section */}
        <Grid container spacing={4} sx={{ mt: 2 }}>
          <Grid item xs={12}>
            <Typography
              variant="h5"
              sx={{
                fontWeight: "medium",
                fontFamily: "'Manrope', sans-serif",
                textAlign: { xs: "center", md: "left" },
              }}
            >
              Real Connections.
            </Typography>
            <Typography
              variant="body1"
              sx={{
                mt: 1,
                fontFamily: "'Manrope', sans-serif",
                textAlign: { xs: "center", md: "left" },
              }}
            >
              Build lasting fitness friendships, from{" "}
              <span style={{ color: "#315fd9" }}>long-term</span> buddies to{" "}
              <span style={{ color: "#fc7032" }}>real-time</span> workout
              partners.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box
              component="img"
              src={real_connections_photo}
              alt="Real Connections"
              sx={{ width: "100%", borderRadius: "16px" }}
            />
          </Grid>
        </Grid>

        {/** Third Section */}
        <Grid container spacing={4} sx={{ mt: 2 }}>
          <Grid item xs={12}>
            <Typography
              variant="h5"
              sx={{
                fontWeight: "medium",
                fontFamily: "'Manrope', sans-serif",
                textAlign: { xs: "center", md: "right" },
              }}
            >
              Consistency Rewards.
            </Typography>
            <Typography
              variant="body1"
              sx={{
                mt: 1,
                fontFamily: "'Manrope', sans-serif",
                textAlign: { xs: "center", md: "right" },
              }}
            >
              Stay active, earn points, and unlock exclusive rewards for your{" "}
              <span style={{ color: "#315fd9" }}>dedication</span>.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box
              component="img"
              src={consistency_reward_photo}
              alt="Consistency Rewards"
              sx={{ width: "100%", borderRadius: "16px" }}
            />
          </Grid>
        </Grid>

        <Typography
          variant="h6"
          sx={{ textAlign: "center", fontWeight: "medium", mt: 4 }}
        >
          Learn More
        </Typography>

        <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            alignContent: "center",
            justifyContent: "center",
            textAlign: "center",
            gap: "20px",
            mt: 4,
        }}>
            <FluidEngine />
            <MailForm />
            <Sponsors />
        </Box>
      </Container>

      <Footer />
    </Box>
  );
};

export default HomePageMobile;
