import LinkedInIcon from "@mui/icons-material/LinkedIn";
import {
  Avatar,
  Box,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import React from "react";
import Header from "../components/Header.jsx";
import Footer from "../components/Footer";
import Emmanuel from "../img/Team/Emmanuel.jpg";
import Inheritance from "../img/Team/Inheritance.jpg";
import Olamide from "../img/Team/Olamide.jpg";
import Joseph from "../img/Team/Joseph.jpg";
import Dharmanshi from "../img/Team/Dharmanshi.jpg";
import Anthony from "../img/Team/Anthony2.png";
import Joanne from "../img/Team/Joanne.jpg";
import "./TeamPage.css";


const TeamPage = () => {
  return (
    <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
      <Header />

      <Box
        sx={{
          width: "85%",
          margin: "0 auto",
          textAlign: "center",
          mt: 8,
          mb: 12,
        }}
      >
        <Typography variant="h2" sx={{ fontWeight: 500, mb: 4, fontSize: {xs: "2rem", md: "3rem" } }}>
          Meet the LyncFit team...
        </Typography>

        {/* Done Manually */}
        <div className="grid-container" style={{ width: "100%" }}>
          <div className="row two-col">

            <div className="grid-item">
              <div className="circle">
                <div className="avatar-row">
                    <Avatar
                        src={Emmanuel}
                        alt="Emmanuel Dei"
                        sx={{ 
                            width: "250px", /* Bigger size */
                            height: "250px", /* Bigger size */
                            margin: "0 auto" 
                        }}
                    />
                </div>
                <div className="name-row">
                    <Typography variant="h4" sx={{ fontWeight: 500, mt: 2 }}>
                        Emmanuel Dei
                    </Typography>
                </div>
                <div className="role-row">
                    <Typography
                      variant="body1"
                      sx={{
                        color: "#212020",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "5px",
                      }}
                    >
                        Co-Founder & CEO
                        
                        <Link
                            href="https://www.linkedin.com/in/emmanuel-dei/"
                            target="_blank"
                            rel="noopener"
                            sx={{ display: "inline-flex", alignItems: "center" }}
                        >
                            <LinkedInIcon sx={{ fontSize: "2rem", ml: 0.5 }} />
                        </Link>
                        
                    </Typography>
                </div>
              </div>
            </div>
            <div className="grid-item">
              <div className="circle">
                <div className="avatar-row">
                    <Avatar
                        src={Inheritance}
                        alt="Inheritance Odili"
                        sx={{ 
                            width: "250px",
                            height: "250px",
                            margin: "0 auto" 
                        }}
                    />
                </div>

                <div className="name-row">
                    <Typography variant="h4" sx={{ fontWeight: 500, mt: 2 }}>
                        Inheritance Odili
                    </Typography>
                </div>
                <div className="role-row">
                    <Typography
                        variant="body1"
                        sx={{
                        color: "#212020",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "5px",
                        }}
                    >
                        Co-Founder & COO
                        
                        <Link
                            href="https://www.linkedin.com/in/inheritance-odili/"
                            target="_blank"
                            rel="noopener"
                            sx={{ display: "inline-flex", alignItems: "center" }}
                        >
                            <LinkedInIcon sx={{ fontSize: "2rem", ml: 0.5 }} />
                        </Link>
                        
                    </Typography>
                </div>
              </div>
            </div>
          </div>
          <div className="row three-col">
            <div className="grid-item blue">
              <div className="circle">
                <div className="avatar-row">
                    <Avatar
                        src={Olamide}
                        alt="Olamide Batula"
                        sx={{ 
                            width: "250px", /* Bigger size */
                            height: "250px", /* Bigger size */
                            margin: "0 auto" 
                        }}
                    />
                </div>
                <div className="name-row">
                    <Typography variant="h4" sx={{ fontWeight: 500, mt: 2 }}>
                        Olamide Batula
                    </Typography>
                </div>
                <div className="role-row">
                    <Typography
                        variant="body1"
                        sx={{
                        color: "#212020",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "5px",
                        }}
                    >
                        CMO
                        
                        <Link
                            href="https://www.linkedin.com/in/olamide-batula-71480a1b0/"
                            target="_blank"
                            rel="noopener"
                            sx={{ display: "inline-flex", alignItems: "center" }}
                        >
                            <LinkedInIcon sx={{ fontSize: "2rem", ml: 0.5 }} />
                        </Link>
                        
                    </Typography>
                </div>
              </div>
            </div>
            <div className="grid-item blue">
              <div className="circle">
                <div className="avatar-row">
                    <Avatar
                        src={Joseph}
                        alt="Joseph Gbadebo"
                        sx={{ 
                            width: "250px", /* Bigger size */
                            height: "250px", /* Bigger size */
                            margin: "0 auto" 
                        }}
                    />
                </div>
                <div className="name-row">
                    <Typography variant="h4" sx={{ fontWeight: 500, mt: 2 }}>
                        Joseph Gbadebo
                    </Typography>
                </div>
                <div className="role-row">
                    <Typography
                        variant="body1"
                        sx={{
                        color: "#212020",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "5px",
                        }}
                    >
                        Marketing Lead
                        
                        <Link
                            href="https://www.linkedin.com/in/joseph-gbadebo09/"
                            target="_blank"
                            rel="noopener"
                            sx={{ display: "inline-flex", alignItems: "center" }}
                        >
                            <LinkedInIcon sx={{ fontSize: "2rem", ml: 0.5 }} />
                        </Link>
                        
                    </Typography>
                </div>
              </div>
            </div>
            <div className="grid-item blue">
              <div className="circle">
                <div className="avatar-row">
                    <Avatar
                        src={Dharmanshi}
                        alt="Dharmanshi Sangani"
                        sx={{ 
                            width: "250px", /* Bigger size */
                            height: "250px", /* Bigger size */
                            margin: "0 auto" 
                        }}
                    />
                </div>
                <div className="name-row">
                    <Typography variant="h4" sx={{ fontWeight: 500, mt: 2 }}>
                        Dharmanshi Sangani
                    </Typography>
                </div>
                <div className="role-row">
                    <Typography
                        variant="body1"
                        sx={{
                        color: "#212020",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "5px",
                        }}
                    >
                        CTO
                        
                        <Link
                            href="https://www.linkedin.com/in/dharmanshi-sangani-172b9719b/"
                            target="_blank"
                            rel="noopener"
                            sx={{ display: "inline-flex", alignItems: "center" }}
                        >
                            <LinkedInIcon sx={{ fontSize: "2rem", ml: 0.5 }} />
                        </Link>
                        
                    </Typography>
                </div>
              </div>
            </div>
          </div>

          <div className="row two-col">

            <div className="grid-item">
              <div className="circle">
                <div className="avatar-row">
                    <Avatar
                        src={Anthony}
                        alt="Anthony Imafidon"
                        sx={{ 
                            width: "250px", /* Bigger size */
                            height: "250px", /* Bigger size */
                            margin: "0 auto" 
                        }}
                    />
                </div>
                <div className="name-row">
                    <Typography variant="h4" sx={{ fontWeight: 500, mt: 2 }}>
                        Anthony Imafidon
                    </Typography>
                </div>
                <div className="role-row">
                    <Typography
                      variant="body1"
                      sx={{
                        color: "#212020",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "5px",
                      }}
                    >
                        Backend Engineer Intern
                        
                        <Link
                            href="https://www.linkedin.com/in/anthony-imafidon-127482130/"
                            target="_blank"
                            rel="noopener"
                            sx={{ display: "inline-flex", alignItems: "center" }}
                        >
                            <LinkedInIcon sx={{ fontSize: "2rem", ml: 0.5 }} />
                        </Link>
                        
                    </Typography>
                </div>
              </div>
            </div>
            <div className="grid-item">
              <div className="circle">
                <div className="avatar-row">
                    <Avatar
                        src={Joanne}
                        alt="Inheritance Odili"
                        sx={{ 
                            width: "250px",
                            height: "250px",
                            margin: "0 auto" 
                        }}
                    />
                </div>

                <div className="name-row">
                    <Typography variant="h4" sx={{ fontWeight: 500, mt: 2 }}>
                        Joanne Lee
                    </Typography>
                </div>
                <div className="role-row">
                    <Typography
                        variant="body1"
                        sx={{
                        color: "#212020",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "5px",
                        }}
                    >
                        Frontend Engineer Intern
                        
                        <Link
                            href="https://www.linkedin.com/in/joan-joanne-lee/"
                            target="_blank"
                            rel="noopener"
                            sx={{ display: "inline-flex", alignItems: "center" }}
                        >
                            <LinkedInIcon sx={{ fontSize: "2rem", ml: 0.5 }} />
                        </Link>
                        
                    </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>

        
      </Box>
      <Footer />
    </Box>
  );
};

export default TeamPage;