import React from 'react';
import { FaFacebook, FaLinkedin, FaInstagram, FaTiktok } from 'react-icons/fa';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer-container">
      {/* Copyright Section */}
      <div className="footer-item">© LyncFit. 2024</div>

      {/* Right Section */}
      <div className="footer-right">
        {/* Keep Updated Section */}
        <section className="section">
        <h4 className="section-title" 
            style={{
              Color: '#000000',
              fontSize: '24px',
              fontFamily: 'Arial',
            }}
          >Keep updated</h4>
          <div className="social-icons">
          <a
              href="https://www.facebook.com/LyncFit"
              className="social-icon"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Visit our Facebook"
            >
              <FaFacebook />
            </a>
            <a
              href="https://www.linkedin.com/company/lyncfit-ltd/"
              className="social-icon"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Visit our LinkedIn"
            >
              <FaLinkedin />
            </a>
            <a
              href="https://www.instagram.com/lyncfit/profilecard/?igsh=cmJ5M3BjZ3VpN3Bh"
              className="social-icon"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Visit our Instagram"
            >
              <FaInstagram />
            </a>
            <a
              href="https://www.tiktok.com/@lyncfit?_t=8r983WK5lR2&_r=1"
              className="social-icon"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Visit our TikTok"
            >
              <FaTiktok />
            </a>
          </div>
        </section>

        {/* Contact Section */}
        <section className="section">
          <h4 className="section-title" 
            style={{
              Color: '#000000',
              fontSize: '24px',
              fontFamily: 'Arial',
            }}
          >
            Contact
          </h4>
          <a
            href="mailto:info@lyncfit.co.uk"
            className="contact-email"
            aria-label="Email us at info@lyncfit.co.uk"
            style={{
              color: '#000000',
              textDecoration: 'underline',
            }}
          >
            info@lyncfit.co.uk
          </a>
        </section>
      </div>
    </footer>
  );
};

export default Footer;