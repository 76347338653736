import React from "react";
import { useMediaQuery } from "react-responsive";
import HomePage from "../pages/HomePage.jsx";
import HomePageMobile from "../pages/HomePageMobile.jsx";

const ResponsiveHomePage = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 }); // Mobile breakpoint
  return isMobile ? <HomePageMobile /> : <HomePage />;
};

export default ResponsiveHomePage;
