import { Instagram, LinkedIn } from "@mui/icons-material";
import lyncfit_logo from "../img/LyncFit_Logo.jpg";
import {
  AppBar,
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Link,
  Radio,
  RadioGroup,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ContactForm from "../components/ContactForm";
import Header from "../components/Header";
import Footer from "../components/Footer";


const ContactPage= () => {
  return (
    <>
    <Header />
    <Box 
      sx={{ 
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        minHeight: "calc(100vh - 300px)",
        width: "100%", 
        bgcolor: "background.paper", 
        mt: { xs: 0, md: "10px"},
        pb: 0,
      }}
    >
      <Box
        sx={{
          width: "80%",
          maxWidth: "1200px", // Limits the maximum width of the form
          padding: 0,
          marginBottom: 0,
        }}
      >
        <ContactForm />
      </Box>
    </Box>
    <Footer />
    </>
  );
};

export default ContactPage;
