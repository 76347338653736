import {
  Box,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import LoadingButton from './join_button';

const MailFormPopUp = () => {
  const [submitted, setSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    email: ''
  });
  const [status, setStatus] = useState({ message: '', type: '' });
  const [emailError, setEmailError] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      setEmailError("Email is not valid. Email addresses should follow the format user@domain.com.");
      return { ok: false };
    }

    setEmailError("");

    try {
      const response = await fetch('https://13.61.4.160/api/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (response.ok) {
        setStatus({
          message: "Subscription successful!",
          type: 'success',
        });
        setFormData({ email: "" });

        // Hide the success message after 3 seconds and set form as submitted
        setTimeout(() => {
          setStatus({ message: '', type: '' }); // Clear success message
          setSubmitted(true); // Optional: Mark the form as submitted
        }, 3000);

        return response;
      } else {
        setStatus({
          message: data.error || 'Something went wrong',
          type: 'error',
        });
        return response;
      }
    } catch (error) {
      setStatus({
        message: 'Failed to submit form. Please try again.',
        type: 'error',
      });
      return { ok: false };
    }
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
    setEmailError("");
  };

  return (
    <Box 
      sx={{ 
        mt: 0, 
        bgcolor: "white", 
        p: 2,
        borderRadius: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        maxWidth: "400px", // Set max width to prevent stretching
        margin: "0 auto", // Center horizontally
        boxSizing: "border-box", // Include padding and border in width
      }}
    >
      {!submitted ? (
        <>
          <LoadingButton
            formData={formData}
            handleChange={handleChange}
            emailError={emailError}
            handleSubmit={handleSubmit}
            sx={{
              mt: 2,
              width: "100%", // Ensure the button takes full width of the container
              maxWidth: "100%",
              backgroundColor: "#315FD9",
              '&:hover': {
                backgroundColor: "#274CAD",
              },
            }}
          />
          {status.type === 'error' && status.message && (
            <Typography
              variant="body1"
              sx={{ 
                color: "red", 
                mt: 2, 
                textAlign: "center",
              }}
            >
              {status.message}
            </Typography>
          )}
        </>
      ) : (
        <Typography variant="h6"
          sx={{ color: "#315fd9", fontWeight: "bold", mt: 2, textAlign: "center" }}
        >
          Watch this space for news and updates!
        </Typography>
      )}
    </Box>
  );
};

export default MailFormPopUp;
