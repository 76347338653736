import React from "react";
import { useMediaQuery } from "react-responsive";
import TeamPage from "../pages/TeamPage.jsx";
import TeamPageMobile from "../pages/TeamPageMobile.jsx";

const ResponsiveTeamPage = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 }); // Mobile breakpoint
  return isMobile ? <TeamPageMobile /> : <TeamPage />;
};

export default ResponsiveTeamPage;
