import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import couple_cyclist_photo from "../img/Couple_Cyclists.jpg";
import real_connections_photo from "../img/Real_Connections.jpg";
import consistency_reward_photo from "../img/Consistency_Reward.jpg";
import Header from "../components/Header.jsx";
import Footer from "../components/Footer.jsx";
import FluidEngine from "../components/FluidEngine.jsx";
import Popup from "../components/Popup.jsx";
import MailForm from "../components/mailform.jsx";
import Sponsors from "../components/Sponsors.jsx";
import wireframe from "../img/Wireframe.png";

const HomePage = () => {
  return (
    <Box sx={{ width: "100%", bgcolor: "white", overflowX: "hidden", }}>
      <Header />
      <Popup />

      <br></br><br></br>
      <Box
        sx={{
          width: "90%",
          margin: "5px auto",
          marginTop: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "16px",
          backgroundColor: "white",
          overflow: "hidden",
        }}
      >
        <Grid container spacing={4} alignItems="center" alignContent="center">
          <Grid item xs={12} md={6}>
          <Typography
            variant="h2"
            sx={{
              fontWeight: "bold",
              fontFamily: "'Manrope', sans-serif",
              color: "#000",
              textAlign: "left",
              marginLeft: "30px", // Corrected from 'leftMargin'
              fontSize: { xs: "3.5rem", sm: "4rem", md: "3rem" }, // Responsive font size
              lineHeight: 1.25, // Spreads out the lines
              letterSpacing: "0.05em", // Slight letter spacing for better readability
            }}
          >
            Connect.
            <br />
            Train.
            <br />
            Achieve Together.
          </Typography>

          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src={wireframe}
              alt="Wireframe Design"
              sx={{ width: "100%", borderRadius: "16px" }}
            />
          </Grid>
        </Grid>
      </Box>
      <br></br>



      <Container>
        <Grid container spacing={4} sx={{ mt: 4, alignItems: "center" }}>
          
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              sx={{ width: "100%", borderRadius: "16px" }}
              alt="Beautiful athletic"
              src={couple_cyclist_photo}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h2"
              sx={{ fontWeight: "medium", fontFamily: "'Manrope', sans-serif", textAlign: "right" }}
            >
              Perfect Workout Partners.
            </Typography>
            <Typography
              variant="h5"
              sx={{ mt: 2, fontFamily: "'Manrope', sans-serif", textAlign: "right" }}
            >
              Find{" "}
              <span style={{ color: "#fc7032" }}>
                like-minded fitness buddies
              </span>{" "}
              who keep you motivated and on track.
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={4} sx={{ mt: 4, alignItems: "center" }}>
          
          <Grid item xs={12} md={6}>
            <Typography
              variant="h2"
              sx={{
                fontWeight: "medium",
                textAlign: "left",
                fontFamily: "'Manrope', sans-serif",
              }}
            >
              Real Connections.
            </Typography>
            <Typography
              variant="h5"
              sx={{
                mt: 2,
                textAlign: "left",
                fontFamily: "'Manrope', sans-serif",
              }}
            >
              Build lasting fitness friendships, from{" "}
              <span style={{ color: "#315fd9" }}>long-term</span> buddies to{" "}
              <span style={{ color: "#fc7032" }}>real-time</span> workout
              partners.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              sx={{ width: "100%", borderRadius: "16px" }}
              alt="Real connections"
              src={real_connections_photo}
            />
          </Grid>
        </Grid>

        <Grid container spacing={4} sx={{ mt: 4, alignItems: "center" }}>
          
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              sx={{ width: "100%", borderRadius: "16px" }}
              alt="Unsplash image"
              src={consistency_reward_photo}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h2"
              sx={{ fontWeight: "medium", fontFamily: "'Manrope', sans-serif", textAlign: "right" }}
            >
              Consistency Rewards.
            </Typography>
            <Typography
              variant="h5"
              sx={{ mt: 2, fontFamily: "'Manrope', sans-serif", textAlign: "right" }}
            >
              Stay active, earn points, and unlock exclusive rewards for your{" "}
              <span style={{ color: "#315fd9" }}>dedication</span>.
            </Typography>
          </Grid>
        </Grid>
        
        <Typography
          variant="h3"
          sx={{ textAlign: "center", fontWeight: "medium", mt: 4 }}
        >
          Learn More
        </Typography>
        <br></br>
        

        <FluidEngine />
        {' '}{' '}
        &nbsp;&nbsp;&nbsp;&nbsp;
        
        <MailForm />

        <Sponsors />

      </Container>
      <Footer />
    </Box>
  );
};

export default HomePage;
