import { useEffect, useState } from 'react';
import { Button, TextField, Box } from '@mui/material';

function LoadingButton({ formData, handleChange, emailError, handleSubmit }) {
    const [state, setState] = useState('idle');
    const [loadingProgress, setLoadingProgress] = useState(0);
    const [success, setSuccess] = useState(false);

    const getButtonText = () => {
      switch (state) {
        case 'loading':
          return '---';
        case 'success':
          return '✓';
        case 'error':
          return '⟳';
        default:
          return 'Join';
      }
    };

    const onSubmit = async (event) => {
      event.preventDefault();
      setState('loading');
      setLoadingProgress(0);
    
      try {
        const response = await handleSubmit(event); // Wait for handleSubmit to complete
        if (response.ok) {
          setState('success');
          setSuccess(true);
        } else {
          setState('error');
        }
      } catch (error) {
        setState('error');
      } finally {
        setLoadingProgress(100);
      }
    };
    

    return (
      <Box component="form" onSubmit={onSubmit} sx={{ display: 'flex', gap: 2 }}>
        <TextField
          name="email"
          type="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Enter your email"
          error={!!emailError}
          helperText={emailError}
          sx={{ 
            '& .MuiOutlinedInput-root': {
              borderRadius: '50px',
              bgcolor: 'white',
            }
          }}
        />
        <Button
          type="submit"
          disabled={state === 'loading'}
          sx={{ 
              bgcolor: "#315fd9", 
              color: "white", 
              borderRadius: "50px", 
              px: 4,
              '&:hover': {
                bgcolor: "#2347b0"
              }
            }}
        >
          {getButtonText()}
        </Button>
      </Box>
    );
}

export default LoadingButton;