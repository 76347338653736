import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import ResponsiveContactUsPage from './components/ResponsiveContactUsPage.jsx';
import ResponsiveTeamPage from './components/ResponsiveTeamPage.jsx';
import ResponsiveHomePage from './components/ResponsiveHomePage.jsx';


function App() {
  return (
    <Router>
      <Routes>
        {/* Route paths and corresponding components */}
        <Route path="/" element={<ResponsiveHomePage />} />
        <Route path="/contact-us" element={<ResponsiveContactUsPage />} />
        <Route path="/team" element={<ResponsiveTeamPage />} />

      </Routes>
    </Router>
  )
}

export default App;
