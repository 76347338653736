import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Backdrop,
  DialogTitle,
} from "@mui/material";
import MailFormPopup from "./mailformpopup.jsx"; // Import the MailForm component

const Popup = () => {
  const [showPopup, setShowPopup] = useState(false); // Show popup initially

  useEffect(() => {
    const popupShown = localStorage.getItem('popupShown');
    if (!popupShown) {
      setShowPopup(true);
      localStorage.setItem('popupShown', true);
    }
  }, []);
  
  const handleClosePopup = () => {
    setShowPopup(false); // Close the dialog when user clicks the close button
  };

  return (
    <Backdrop open={showPopup} sx={{ zIndex: 1300 }}>
      <Dialog
        open={showPopup} // Only show the dialog if showPopup is true
        onClose={handleClosePopup}
        maxWidth="xs"
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "16px",
            padding: "20px",
            backgroundColor: "white",
            boxSizing: "border-box",
          },
        }}
      >
        <DialogActions
          sx={{
            position: "absolute",
            top: 0,
            right: "8px",
            padding: "0",
          }}
        >
          <Typography
            onClick={handleClosePopup}
            sx={{
              cursor: "pointer",
              color: "#3366cc",
              fontSize: "1rem",
              padding: "8px",
              fontWeight: "bold",
            }}
          >
            X
          </Typography>
        </DialogActions>

        <DialogTitle 
          sx={{ 
            fontWeight: "medium", 
            color: "#315FD9", 
            textAlign: "center", 
            fontSize: "1.75rem",
            margin: 0,
            paddingTop: 0,
          }}
        >
          Join the Waitlist!
          <br />
          <p style={{ mt: 1, fontSize: "0.85rem", color: "grey", marginBottom: 0 }}>
            Be the first to know what we’re up to!
          </p>
        </DialogTitle>
        
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 0,
            marginTop: "-8px",
          }}
        >
          <div style={{ width: "100%" }}>
            <MailFormPopup /> {/* Reuse MailForm component */}
          </div>
        </DialogContent>
       
      </Dialog>
    </Backdrop>
  );
};

export default Popup;
