import React from 'react';
import UoBElevateLogo from '../img/UoBElevate_Logo.png';
import goLondon from '../img/Go_London.png';
import { Typography, Box } from '@mui/material';

const Sponsors = () => {
  return (
    <Box 
      sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center', 
        padding: '40px', 
        width: "100%", 
      }}
    >
      
{/*       <Typography 
        variant="h3" 
        sx={{ 
          fontSize: { xs: '1.8rem', md: '2.5rem' }, 
          fontWeight: 'bold',
          marginBottom: '30px',
          textAlign: 'center',
        }}
      >
        Proudly supported by
      </Typography> */}

      <Typography variant="h3" sx={{ fontWeight: "medium", color: "#000000" }}>
              Proudly supported by
      </Typography>
      <br></br>
      
      <Box 
        sx={{ 
          display: 'flex', 
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'center', 
          alignItems: 'center', 
          gap: { xs: '20px', md: '50px' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: { xs: '80%', md: 'auto' },
            height: { md: '200px' },
          }}
        >
          <img
            src={goLondon}
            alt="School for Social Entrepreneurs"
            style={{ 
              width: '100%', 
              height: '100%', 
              objectFit: 'contain',
            }}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: { xs: '80%', md: 'auto' },
            height: { md: '200px' },
          }}
        >
          <img
            src={UoBElevateLogo}
            alt="University of Birmingham Elevate Logo"
            style={{ 
              width: '100%', 
              height: '100%', 
              objectFit: 'contain',
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Sponsors;
