import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const FluidEngine = () => {
  const [expanded, setExpanded] = useState(null);

  const handleExpand = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  const items = [
    {
      title: "What is LyncFit?",
      content:
        "LyncFit is an AI-powered fitness app that matches users with compatible workout partners to boost motivation, accountability, and long-term fitness consistency.",
    },
    {
      title: "Who is LyncFit designed for?",
      content:
        "LyncFit is tailored for fitness enthusiasts, including university students, young professionals, and newcomers who are seeking support, accountability, and companionship in their fitness journey.",
    },
    {
      title:
        "Can universities, corporate organisations, and gyms use LyncFit for wellness programs?",
      content:
        "Yes, LyncFit partners with universities, corporate organisations, and gyms to enhance wellness and productivity through personalised fitness programs tailored to students, employees, and gym members.",
    },
    {
      title: "How is Artificial Intelligence incorporated into LyncFit?",
      content:
        "Our AI algorithm matches users based on factors such as fitness goals, preferences, location, and personality traits to ensure compatibility and optimal workout results.",
    },
    {
      title: "Does LyncFit support group workouts?",
      content:
        "Yes! In addition to one-on-one matching, LyncFit supports group workouts where users can join or create workout groups based on shared interests and goals.",
    },
    {
      title: "When is LyncFit launching?",
      content: "LyncFit is scheduled to launch in the summer of 2025.",
    },
    {
      title: "How does LyncFit contribute to environmental sustainability?",
      content:
        "For every new user or milestone achieved on the platform, we plant a tree through our partnership with Ecologi, promoting both personal health and environmental well-being.",
    },
    {
      title: "What types of rewards or gamification does LyncFit offer?",
      content:
        "LyncFit rewards users with points based on the consistency of their connections, redeemable for fitness gear, food, and everyday essentials to keep them motivated.",
    },
  ];

  return (
    <Box id="outest"
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        columnGap: "0", // No space between columns
        maxWidth: "100%",
        margin: "0 auto",
      }}
    >
      {[items.slice(0, 4), items.slice(4)].map((columnItems, columnIndex) => (
        <Box id="outer"
          key={columnIndex}
          sx={{
            flex: 1,
            display: "block", // Make each column behave like a block element
          }}
        >
          {columnItems.map((item, index) => {
            const globalIndex = index + columnIndex * 4;
            return (
              <Accordion 
                key={globalIndex}
                expanded={expanded === globalIndex}
                onChange={handleExpand(globalIndex)}
                sx={{
                  boxShadow: "none",
                  borderTop: "1px solid black",
                  borderBottom:
                    globalIndex === 3 || globalIndex === 7
                      ? "1px solid black"
                      : "none",
                  transition: "all 0.3s ease", // Smooth transition
                  marginBottom: 0,
                  borderRadius: 0,
                }}
              >
                <AccordionSummary
                  expandIcon={
                    expanded === globalIndex ? <RemoveIcon /> : <AddIcon />
                  }
                  aria-controls={`panel${globalIndex}-content`}
                  id={`panel${globalIndex}-header`}
                  aria-expanded={expanded === globalIndex}
                  role="button"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center", // Ensures horizontal centering
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: "400",
                      fontSize: "1.25rem",
                      textAlign: "center", // Center-align the text
                      width: "100%", // Ensures the title spans the full width
                    }}
                  >
                    {item.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    paddingBottom: "0px",
                    paddingLeft: "16px",
                    paddingRight: "16px",
                    textAlign: "center", // Center-align text in details
                    lineHeight: "1.6",
                    fontSize: "1rem",
                  }}
                  id={`panel${globalIndex}-content`}
                  role="region"
                  aria-labelledby={`panel${globalIndex}-header`}
                >
                  <Typography>{item.content}</Typography>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Box>
      ))}
    </Box>
  );
};

export default FluidEngine;


