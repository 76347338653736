import React, { useState } from 'react';
import {
    AppBar,
    Box,
    Toolbar,
    Typography,
    IconButton,
    Drawer,
    List,
    ListItem,
    ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

import lyncfit_logo from "../img/LyncFit_Logo.jpg";

import { Link, useLocation } from 'react-router-dom';

const Header = () => {
    const location = useLocation();
    const [drawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    const menuItems = [
        { text: "The Team", path: "/team" },
        { text: "Contact Us", path: "/contact-us" },
    ];

    return (
        <>
            <AppBar
                position="static"
                sx={{
                    bgcolor: "white",
                    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.15)",
                    paddingX: "4%",
                    height: "120px",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <Toolbar
                    sx={{
                        minHeight: 120,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}
                >
                    <Link to="/" style={{ textDecoration: 'none' }}>
                        <Box
                            component="img"
                            sx={{
                                height: { xs: 50, md: 75 },
                                width: { xs: 135, md: 203 },
                                display: "block",
                                margin: "auto"
                            }}
                            alt="Logo"
                            src={lyncfit_logo}
                        />
                    </Link>

                    {/* Mobile View: Hamburger Menu */}
                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            color="inherit"
                            aria-label="menu"
                            onClick={toggleDrawer(true)}
                        >
                            <MenuIcon sx={{ color: "#315fd9" }} />
                        </IconButton>
                    </Box>

                    {/* Desktop View: Navigation Links */}
                    <Box
                        sx={{
                            display: { xs: 'none', md: 'flex' },
                            flexGrow: 1,
                            justifyContent: 'flex-end',
                            alignItems: "center",
                            gap: 3
                        }}
                    >
                        {menuItems.map((item) => (
                            <Typography
                                key={item.text}
                                variant="body2"
                                component="div"
                                sx={{ color: "#3f3f3f" }}
                            >
                                <Link
                                    to={item.path}
                                    style={{
                                        color: "#315fd9",
                                        textDecorationLine: location.pathname === item.path ? 'underline' : 'none',
                                        textDecorationThickness: location.pathname === item.path ? '2px' : 'none',
                                        textUnderlineOffset: location.pathname === item.path ? '4px' : 'none',
                                    }}
                                >
                                    {item.text}
                                </Link>
                            </Typography>
                        ))}
                    </Box>
                </Toolbar>
            </AppBar>

            {/* Mobile Drawer */}
            <Drawer
                anchor="top"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: '100%',
                        height: '100%',
                        top: 0,
                        bgcolor: 'white',
                    },
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start", // Align content to the top
                        alignItems: "center",
                        height: '100%',
                        paddingTop: 4,
                    }}
                    role="presentation"
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                >
                    {/* Logo at the top of the drawer */}
                    <Link to="/" style={{ textDecoration: 'none' }}>
                        <Box
                            component="img"
                            sx={{
                                height: 50,
                                width: 135,
                                marginBottom: 4,
                            }}
                            alt="Logo"
                            src={lyncfit_logo}
                        />
                    </Link>
                    <IconButton
                        sx={{
                            position: "absolute",
                            top: 16,
                            right: 16,
                        }}
                        onClick={toggleDrawer(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                    <List>
                        {menuItems.map((item) => (
                            <ListItem button key={item.text}>
                                <Link
                                    to={item.path}
                                    style={{
                                        textDecoration: "none",
                                        color: location.pathname === item.path ? "#315fd9" : "#3f3f3f",
                                        fontSize: { xs: "2rem", md: "1.5rem" },
                                        textAlign: "center",
                                    }}
                                >
                                    <ListItemText
                                        primary={item.text}
                                        primaryTypographyProps={{
                                            sx: { fontSize: { xs: '2rem', md: '1rem' }, fontWeight: 'medium' },
                                        }}
                                    />
                                </Link>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Drawer>
        </>
    );
};

export default Header;