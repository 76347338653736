import {
  AppBar,
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Link,
  Radio,
  RadioGroup,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

const ContactForm = () => {
  const [submitted, setSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    contact_type: 'customer', // Default value
    message: '',
  });
  const [status, setStatus] = useState({ message: ' ', type: ' ' });
  const [success, setSuccess] = useState(null);
  const [emailError, setEmailError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    console.log({formData})
    e.preventDefault();

    // Email format validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      setEmailError(
        "Email is not valid. Please enter an email in the format user@domain.com."
      );
      setSubmitted(false);
      return;
    }

    setEmailError("");
    setSubmitted(true);

    console.log("Form data on submit:", formData); // Log form data before submission
    try {
      console.log("Attempting to connect to the server...");
      const response = await fetch('https://13.61.4.160/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
      });
      const data = await response.json();
      console.log("Server response data:", data); // Log server response data
      if (response.ok) {
        console.log("Form submitted successfully!");
        setStatus({
          message: data.message,
          type: 'success'
        });
        setFormData({
          first_name: '',
          last_name: '',
          email: '',
          contact_type: 'customer',
          message: ''
        });
        setSuccess(true);
      } else {
        console.log("Form submission failed with error:", data.error || 'Something went wrong');
        setStatus({
          message: data.error || 'Something went wrong',
          type: 'error'
        });
        setSuccess(false);
      }
    } catch (error) {
      console.error("Error while submitting the form:", error);
      setStatus({
        message: 'Failed to submit form. Please try again.',
        type: 'error'
      });
      setSuccess(false);
    }
  };
  function breakLines() {
    return (
      <>
        {[...Array(5)].map((_, index) => (
          <Box
            key={index}
            sx={{
              display: { xs: index < 2 ? "block" : "none", md: "block" },
            }}
          >
            <br />
          </Box>
        ))}
      </>
    );
  }
  

  return (
    <Box sx={{ mt: 4, bgcolor: "white", p: 3, borderRadius: 2 }}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          {breakLines()}
          <Typography 
            variant="h3" 
            sx={{ 
              fontWeight: "medium", 
              color: "#3366cc", 
              fontFamily: "'Nunito Sans', sans-serif", 
              fontSize: '3.5rem',
              mt: { xs: 0, md: 4 }, 
            }}
          >
            Contact Us
          </Typography>
          <Typography variant="body1" sx={{ mt: 2 }}>
            Get in touch with us. We'd love to hear from you!
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          {/* Contact Form */}
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 4 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="body2"
                  sx={{ color: "#3366cc", fontWeight: "bold" }}
                >
                  First Name (required)
                </Typography>
                <TextField
                  fullWidth
                  name="first_name"
                  // label="First Name"
                  required
                  variant="outlined"
                  value={formData.first_name}
                  onChange={handleChange}
                  sx={{ 
                    backgroundColor: '#f0f0f0', 
                    borderRadius: '50px',
                    mt: 1,
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none", // Initially no border
                      },
                      "&:hover fieldset": {
                        border: "1px solid #767676", // Border on hover
                        borderRadius: '50px',
                        backgroundColor: '#dedede',
                      },
                      "&.Mui-focused fieldset": {
                        border: "2px solid #3366cc", // Border when focused
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
              <Typography
                  variant="body2"
                  sx={{ color: "#3366cc", fontWeight: "bold" }}
                >
                  Last Name (required)
                </Typography>
                <TextField
                  fullWidth
                  // label="Last Name"
                  name="last_name"
                  required
                  variant="outlined"
                  value={formData.last_name}
                  onChange={handleChange}
                  sx={{ 
                    backgroundColor: '#f0f0f0', 
                    borderRadius: '50px',
                    mt: 1,
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none", // Initially no border
                      },
                      "&:hover fieldset": {
                        border: "1px solid #767676", // Border on hover
                        borderRadius: '50px',
                        backgroundColor: '#dedede',
                      },
                      "&.Mui-focused fieldset": {
                        border: "2px solid #3366cc", // Border when focused
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
              <Typography
                  variant="body2"
                  sx={{ color: "#3366cc", fontWeight: "bold" }}
                >
                  Email (required)
                </Typography>
                <TextField
                  fullWidth
                  // label="Email"
                  name="email"
                  required
                  variant="outlined"
                  value={formData.email}
                  onChange={handleChange}
                  error={!!emailError}
                  helperText={emailError}
                  sx={{ 
                    backgroundColor: '#f0f0f0', 
                    borderRadius: '50px',
                    mt: 1,
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none", // Initially no border
                      },
                      "&:hover fieldset": {
                        border: "1px solid #767676", // Border on hover
                        borderRadius: '50px',
                        backgroundColor: '#dedede',
                      },
                      "&.Mui-focused fieldset": {
                        border: "2px solid #3366cc", // Border when focused
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl component="fieldset" required>
                  <FormLabel component="legend">
                    Do you represent a business or organisation? (required)
                  </FormLabel>
                  <RadioGroup
                    row
                    name="contact_type"
                    value={formData.contact_type}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="business"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="customer"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
              <Typography
                  variant="body2"
                  sx={{ color: "#3366cc", fontWeight: "bold" }}
                >
                  Message (required)
                </Typography>
                <TextField
                  fullWidth
                  // label="Message"
                  name="message"
                  required
                  multiline
                  rows={3}
                  variant="outlined"
                  value={formData.message}
                  onChange={handleChange}
                  sx={{ 
                    backgroundColor: '#f0f0f0', 
                    borderRadius: '20px',
                    mt: 1,
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none", // Initially no border
                      },
                      "&:hover fieldset": {
                        border: "1px solid #767676", // Border on hover
                        borderRadius: '20px',
                        backgroundColor: '#dedede',
                      },
                      "&.Mui-focused fieldset": {
                        border: "2px solid #3366cc", // Border when focused
                      },
                    },
                    "& .MuiInputBase-input": {
                      resize: "both",
                      overflow: "auto",
                    },
                  }}
                  InputProps={{
                    sx: {
                      resize: "both",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button 
                  variant="contained" 
                  type="submit" 
                  color="primary"
                  sx={{ 
                    borderRadius: '20px', 
                    px: 5, 
                    py: 1.5,
                    mt: 0,
                    mb: 0,
                  }}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>

            {submitted && success === false && (
              <Typography variant="h6" sx={{ mt: 2, color: "red", fontSize: "0.7rem" }}>
                Submission failed. There was no response from the server.
              </Typography>
            )}
            {submitted && success === true && (
              <Typography variant="h6" sx={{ mt: 2, color: "green", fontSize: "0.7rem" }}>
                Thank you for your submission!
              </Typography>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactForm;
