import React from "react";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Avatar, Box, Link, Typography } from "@mui/material";
import Header from "../components/Header.jsx";
import Footer from "../components/Footer";
import Emmanuel from "../img/Team/Emmanuel.jpg";
import Inheritance from "../img/Team/Inheritance.jpg";
import Olamide from "../img/Team/Olamide.jpg";
import Joseph from "../img/Team/Joseph.jpg";
import Dharmanshi from "../img/Team/Dharmanshi.jpg";
import Anthony from "../img/Team/Anthony2.png";
import Joanne from "../img/Team/Joanne.jpg";


const TeamPageMobile = () => {
  return (
    <Box 
      sx={{ 
        display: "flex", 
        flexDirection: "column",
        minHeight: "100vh",
        //width: "100%", 
        bgcolor: "background.paper",
        }}
      >
      <Header />

      <Box
        sx={{
          flex: "1",
          width: "100%",
          margin: "0 auto",
          textAlign: "center",
          mt: 8,
          mb: 12,
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontWeight: 500,
            mb: 4,
            fontSize: { xs: "1.5rem", md: "3rem" },
          }}
        >
          Meet the LyncFit team...
        </Typography>

        {/* Team Grid */}
        <div
          className="grid-container"
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: 4,
            marginBottom: 4,
          }}
        >
          {/* Team Member Component */}
          {[
            {
              name: "Emmanuel Dei",
              role: "Co-Founder & CEO",
              image: Emmanuel,
              linkedin: "https://www.linkedin.com/in/emmanuel-dei/",
            },
            {
              name: "Inheritance Odili",
              role: "Co-Founder & COO",
              image: Inheritance,
              linkedin: "https://www.linkedin.com/in/inheritance-odili/",
            },
            {
              name: "Olamide Batula",
              role: "CMO",
              image: Olamide,
              linkedin:
                "https://www.linkedin.com/in/olamide-batula-71480a1b0/",
            },
            {
              name: "Joseph Gbadebo",
              role: "Marketing Lead",
              image: Joseph,
              linkedin:
                "https://www.linkedin.com/in/joseph-gbadebo09/",
            },
            {
              name: "Dharmanshi Sangani",
              role: "CTO",
              image: Dharmanshi,
              linkedin:
                "https://www.linkedin.com/in/dharmanshi-sangani-172b9719b/",
            },
            {
              name: "Anthony Imafidon",
              role: "Backend Engineer Intern",
              image: Anthony,
              linkedin:
                "https://www.linkedin.com/in/anthony-imafidon-127482130/",
            },
            {
              name: "Joanne Lee",
              role: "Frontend Engineer Intern",
              image: Joanne,
              linkedin:
                "https://www.linkedin.com/in/joan-joanne-lee/",
            },
          ].map((member, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                width: "100%",
                maxWidth: "400px",
                paddingBottom: "20px",
              }}
            >
              <Avatar
                src={member.image}
                alt={member.name}
                sx={{
                  width: { xs: "150px", md: "250px" },
                  height: { xs: "150px", md: "250px" },
                  marginBottom: 2,
                }}
              />
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 500,
                  fontSize: { xs: "1rem", md: "1.5rem" },
                  marginBottom: 1,
                }}
              >
                {member.name}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#212020",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "5px",
                  fontSize: { xs: "0.8rem", md: "1rem" },
                }}
              >
                {member.role}
                <Link
                  href={member.linkedin}
                  target="_blank"
                  rel="noopener"
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                  }}
                >
                  <LinkedInIcon
                    sx={{
                      fontSize: { xs: "1.5rem", md: "2rem" },
                      ml: 0.5,
                    }}
                  />
                </Link>
              </Typography>
            </Box>
          ))}
        </div>
      </Box>

      <Footer />
    </Box>
  );
};

export default TeamPageMobile;
